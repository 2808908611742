<template>
    <div v-if="isVisible">
        <header-component
            v-bind:url="url"
            v-bind:status="status"
            name="QS Tool"
        ></header-component>

        <div v-if="status === 'ERROR'">
            <div v-if="!isInPdm" class="alert alert-danger">
                Der Artikel ist nicht im PDM.
            </div>

            <div v-if="!hasTestPlan" class="alert alert-danger">
                Der Artikel hat keinen Testplan.
            </div>
        </div>
    </div>
</template>

<script>
import header from "./header.vue";
import { allFinished } from "../utils";

export default {
    components: {
        "header-component": header,
    },
    computed: {
        isVisible() {
            let state = this.$store.state;
            return allFinished(state, [
                "abasArticle",
                "pdmArticle",
                "wdmArticle",
                "pdmTestPlan",
            ]);
        },

        status() {
            if (this.isInWdm && !this.isInPdm) {
                return "IGNORE";
            }
            if (this.hasTestPlan) {
                return "OK";
            }
            return "ERROR";
        },

        abasArticle() {
            return this.$store.state.abasArticle.data;
        },
        pdmArticle() {
            return this.$store.state.pdmArticle.data;
        },
        wdmArticle() {
            return this.$store.state.wdmArticle.data;
        },
        pdmTestPlan() {
            return this.$store.state.pdmTestPlan.data;
        },
        url() {
            if (this.abasArticle == null) {
                return "https://proclima-pdm.moll-group.eu/admin/inspections/testplan/";
            }
            return (
                "https://proclima-pdm.moll-group.eu/admin/inspections/testplan/?q=" +
                this.abasArticle.item_number
            );
        },
        isInPdm() {
            return this.pdmArticle !== null;
        },
        isInWdm() {
            return this.wdmArticle !== null;
        },
        hasTestPlan() {
            return this.pdmTestPlan !== null;
        },
    },
};
</script>
