<template>
    <div>
        <div class="row mb-2">
            <div class="col">
                <div class="form-floating">
                    <input
                        type="text"
                        name="query"
                        class="form-control"
                        v-bind:placeholder="label"
                        v-model:value="query"
                        v-on:keyup.enter="searchArticle"
                    />
                    <label>{{ label }}</label>
                </div>
            </div>
        </div>
        <div class="d-flex gap-2">
            <input
                type="button"
                name="search"
                value="Suchen"
                class="btn col btn-primary"
                v-on:click="searchArticle"
            />
            <input
                type="reset"
                name="reset"
                value="Zurücksetzen"
                class="btn col btn-warning"
                v-on:click="resetSearch"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        articleNumber: String,
    },
    data() {
        return {
            query: this.articleNumber,
        };
    },
    methods: {
        searchArticle() {
            this.$emit("search-article", this.query);
        },
        resetSearch() {
            this.$emit("reset");
        },
    },
    watch: {
        articleNumber(value) {
            this.query = value;
        },
    },
};
</script>
