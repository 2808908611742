<template>
    <div v-if="isVisible">
        <header-component
            v-bind:url="url"
            v-bind:status="status"
            name="simpletrading"
        ></header-component>

        <div v-if="status === 'ERROR'">
            <div
                v-if="!isInRegionOc && onlyInCountry !== 'NZ'"
                class="alert alert-danger"
            >
                Der Artikel ist in ABAS weder in Region "oc" noch auf das Land
                "NZ" beschränkt.
            </div>
            <div
                v-if="isInRegionOc && !!onlyInCountry"
                class="alert alert-danger"
            >
                Der Artikel ist in ABAS in Region "oc" aber auf das Land "{{
                    onlyInCountry
                }}" beschränkt.
            </div>
        </div>
    </div>
</template>

<script>
import header from "./header.vue";
import { allFinished } from "../utils";

export default {
    components: {
        "header-component": header,
    },
    computed: {
        isVisible() {
            let state = this.$store.state;
            return allFinished(state, [
                "abasArticle",
                "pdmArticle",
                "wdmArticle",
            ]);
        },

        status() {
            if (this.isInWdm) {
                return "IGNORE";
            }
            if (
                this.isInPdm &&
                ((this.isInRegionOc && !this.onlyInCountry) ||
                    this.onlyInCountry === "NZ")
            ) {
                return "OK";
            }
            return "ERROR";
        },

        abasArticle() {
            return this.$store.state.abasArticle.data;
        },
        pdmArticle() {
            return this.$store.state.pdmArticle.data;
        },
        wdmArticle() {
            return this.$store.state.wdmArticle.data;
        },

        onlyInCountry() {
            if (this.abasArticle == null) {
                return false;
            }
            return this.abasArticle.only_in_country;
        },
        url() {
            if (this.pdmArticle == null) {
                return null;
            }
            return (
                "https://simpletrading.proclima.co.nz/dashboard/catalogue/?upc=" +
                this.pdmArticle.item_number
            );
        },

        isInWdm() {
            return this.wdmArticle != null;
        },

        isInPdm() {
            return this.pdmArticle != null;
        },
        isInRegionOc() {
            if (this.abasArticle == null) {
                return false;
            }
            return this.abasArticle.region_oc;
        },
    },
};
</script>
