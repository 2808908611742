<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <search
                    label="Artikelnummer"
                    v-bind:articleNumber="$store.state.articleNumber"
                    v-on:search-article="onSearchArticle"
                    v-on:reset="onReset"
                ></search>
            </div>
            <div class="col-sm-6">
                <strong>Beispiel</strong>
                <ul>
                    <li
                        v-for="articleNumber of Object.keys(
                            $store.state.exampleArticleNumbers
                        )"
                    >
                        <a :href="'#' + articleNumber">
                            {{ articleNumber }} –
                            {{
                                $store.state.exampleArticleNumbers[
                                    articleNumber
                                ]
                            }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="$store.state.articleNumber !== ''">
            <h2 class="mb-4">
                Ergebnis für Artikel
                <code>{{ $store.state.articleNumber }}</code>
            </h2>

            <div class="row">
                <div class="col-sm-6">
                    <einfach-packen></einfach-packen>
                    <retailerportal></retailerportal>
                    <simpletrading></simpletrading>
                    <qstool></qstool>
                </div>

                <div class="col-sm-6">
                    <abas-article
                        v-bind:data="$store.state.abasArticle"
                    ></abas-article>

                    <wdm-article
                        v-bind:data="$store.state.wdmArticle"
                    ></wdm-article>

                    <pdm-product
                        v-bind:data="$store.state.pdmProduct"
                    ></pdm-product>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchForm from "./search_form.vue";
import AbasArticle from "./abas_article.vue";
import WdmArticle from "./wdm_article.vue";
import PdmProduct from "./pdm_product.vue";
import EinfachPacken from "./einfach_packen.vue";
import Retailerportal from "./retailerportal.vue";
import Simpletrading from "./simpletrading.vue";
import qstool from "./qstool.vue";

export default {
    components: {
        search: SearchForm,
        abasArticle: AbasArticle,
        wdmArticle: WdmArticle,
        pdmProduct: PdmProduct,
        einfachPacken: EinfachPacken,
        retailerportal: Retailerportal,
        simpletrading: Simpletrading,
        qstool: qstool,
    },
    methods: {
        onSearchArticle(articleNumber) {
            if (articleNumber !== "") {
                this.$store.dispatch("searchArticle", { articleNumber });
            } else {
                this.$store.dispatch("reset");
            }
        },
        onReset() {
            this.$store.dispatch("reset");
        },
    },
    created() {
        this.$store.dispatch("loadPdmCategories");
        this.$store.dispatch("loadWdmCategories");
        var onHashChange = () => {
            if (window.location.hash.length > 1) {
                let articleNumber = window.location.hash.substr(1);
                if (articleNumber !== this.$store.state.articleNumber) {
                    this.$store.dispatch("searchArticle", { articleNumber });
                }
            } else {
                this.$store.dispatch("reset");
            }
        };
        window.addEventListener("hashchange", onHashChange);
        onHashChange();
    },
};
</script>
