<template>
    <remoteData
        v-bind:data="data.data"
        v-bind:status="data.status"
        v-bind:errorMessage="data.errorMessage"
        v-bind:url="url"
        name="WDM"
    >
        <div v-if="article !== null">
            <div class="row">
                <div class="col-sm-10">
                    <dl class="dl-horizontal">
                        <dt>Name</dt>
                        <dd>{{ article.name }}</dd>
                        <dt>Öffentlicher Name</dt>
                        <dd>{{ article.public_name }}</dd>
                        <dt>Beschreibung</dt>
                        <dd>{{ article.description }}</dd>
                        <dt>Startdatum</dt>
                        <dd>{{ article.start_date }}</dd>
                        <dt>Enddatum</dt>
                        <dd>{{ article.end_date }}</dd>
                    </dl>
                </div>
                <div class="col-sm-2">
                    <img
                        class="img-fluid"
                        v-if="article.image_url"
                        v-bind:src="article.image_url"
                        v-bind:alt="article.name"
                    />
                    <span v-else="product.image" class="thumbnail"
                        >Kein Bild</span
                    >
                </div>
            </div>
        </div>
    </remoteData>
</template>

<script>
import RemoteData from "./remote_data.vue";

export default {
    props: {
        data: Object,
    },
    computed: {
        article() {
            return this.data.data;
        },
        url() {
            if (!this.article) {
                return "https://proclima-wdm.moll-group.eu/admin/articles/article/";
            }
            return (
                "https://proclima-wdm.moll-group.eu/admin/articles/article/?q=" +
                this.article.article_number
            );
        },
    },
    components: {
        remoteData: RemoteData,
    },
};
</script>
