import "bootstrap";

import "../styles/styles.scss";

import Vue from "vue";
import store from "./store/index";
import App from "./components/app.vue";

window.addEventListener("load", function () {
    if (document.getElementById("vue-app")) {
        new Vue({
            el: "#vue-app",
            store: store,
            components: {
                app: App,
            },
        });
    }
});
