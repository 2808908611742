import { RESET, SET_ARTICLE_NUMBER, SET_REMOTE_DATA } from "./mutation-types";
import axios from "axios";

const actions = {
    searchArticle({ commit, state, dispatch }, { articleNumber }) {
        commit(RESET);
        commit(SET_ARTICLE_NUMBER, { articleNumber });
        dispatch("loadAbasArticle");
        dispatch("loadWdmArticle");
        dispatch("loadPdmProduct");
        dispatch("loadPdmTestPlan");
        window.location.hash = articleNumber;
    },
    reset({ commit }) {
        window.location.hash = "";
        commit(RESET);
    },
    loadAbasArticle({ commit, state }) {
        commit(SET_REMOTE_DATA, {
            namespace: "abasArticle",
            remoteData: {
                status: "loading",
            },
        });
        axios
            .get("/api/abas/article/" + state.articleNumber + "/")
            .then((response) => {
                commit(SET_REMOTE_DATA, {
                    namespace: "abasArticle",
                    remoteData: {
                        status: "success",
                        data: response.data,
                    },
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "abasArticle",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "abasArticle",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
            });
    },
    loadWdmArticle({ commit, state }) {
        commit(SET_REMOTE_DATA, {
            namespace: "wdmArticle",
            remoteData: {
                status: "loading",
            },
        });
        axios
            .get("/api/wdm/article/" + state.articleNumber + "/")
            .then((response) => {
                commit(SET_REMOTE_DATA, {
                    namespace: "wdmArticle",
                    remoteData: {
                        status: "success",
                        data: response.data,
                    },
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "wdmArticle",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "wdmArticle",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
            });
    },
    loadPdmProduct({ commit, state, dispatch }) {
        commit(SET_REMOTE_DATA, {
            namespace: "pdmProduct",
            remoteData: {
                status: "loading",
            },
        });
        axios
            .get("/api/pdm/product/" + state.articleNumber + "/")
            .then((response) => {
                if (response.data.results.length === 1) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmProduct",
                        remoteData: {
                            status: "success",
                            data: response.data.results[0],
                        },
                    });
                    dispatch("loadPdmArticle");
                } else if (response.data.results.length === 0) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmProduct",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmArticle",
                        remoteData: {
                            status: "error",
                            errorMessage:
                                "Could not load pdm article because pdm product wasn't found.",
                        },
                    });
                } else {
                    const errorMessage =
                        "" +
                        response.data.results.length +
                        " Ergebnisse gefunden, es wird aber genau ein Ergebniss erwartet.";
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmProduct",
                        remoteData: {
                            status: "error",
                            errorMessage: errorMessage,
                        },
                    });
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmArticle",
                        remoteData: {
                            status: "error",
                            errorMessage:
                                "Could not load pdm article because pdm product wasn't found.",
                        },
                    });
                }
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmProduct",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmProduct",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
                commit(SET_REMOTE_DATA, {
                    namespace: "pdmArticle",
                    remoteData: {
                        status: "error",
                        errorMessage:
                            "Could not load pdm article because pdm product wasn't found.",
                    },
                });
            });
    },
    loadPdmArticle({ commit, state }) {
        commit(SET_REMOTE_DATA, {
            namespace: "pdmArticle",
            remoteData: {
                status: "loading",
            },
        });
        let gtin = null;
        for (const formOfDelivery of state.pdmProduct.data.forms_of_delivery) {
            if (formOfDelivery.item_number === state.articleNumber) {
                gtin = formOfDelivery.gtin;
                break;
            }
        }

        if (gtin == null) {
            const errorMessage =
                "Es konnte keine Lieferform für den Artikel gefunden werden";
            commit(SET_REMOTE_DATA, {
                namespace: "pdmArticle",
                remoteData: {
                    status: "error",
                    errorMessage: errorMessage,
                },
            });
            return;
        }

        axios
            .get("/api/pdm/article/" + gtin + "/")
            .then((response) => {
                commit(SET_REMOTE_DATA, {
                    namespace: "pdmArticle",
                    remoteData: {
                        status: "success",
                        data: response.data,
                    },
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmArticle",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmArticle",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
            });
    },
    loadPdmTestPlan({ commit, state }) {
        commit(SET_REMOTE_DATA, {
            namespace: "pdmTestPlan",
            remoteData: {
                status: "loading",
            },
        });

        axios
            .get("/api/pdm/test_plan/" + state.articleNumber + "/")
            .then((response) => {
                commit(SET_REMOTE_DATA, {
                    namespace: "pdmTestPlan",
                    remoteData: {
                        status: "success",
                        data: response.data,
                    },
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmTestPlan",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmTestPlan",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
            });
    },
    loadPdmCategories({ commit }) {
        commit(SET_REMOTE_DATA, {
            namespace: "pdmCategories",
            remoteData: {
                status: "loading",
            },
        });
        axios
            .get("/api/pdm/category/")
            .then((response) => {
                commit(SET_REMOTE_DATA, {
                    namespace: "pdmCategories",
                    remoteData: {
                        status: "success",
                        data: response.data.results,
                    },
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmCategories",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "pdmCategories",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
            });
    },
    loadWdmCategories({ commit }) {
        commit(SET_REMOTE_DATA, {
            namespace: "wdmCategories",
            remoteData: {
                status: "loading",
            },
        });
        axios
            .get("/api/wdm/category/")
            .then((response) => {
                commit(SET_REMOTE_DATA, {
                    namespace: "wdmCategories",
                    remoteData: {
                        status: "success",
                        data: response.data.results,
                    },
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    commit(SET_REMOTE_DATA, {
                        namespace: "wdmCategories",
                        remoteData: {
                            status: "not_found",
                        },
                    });
                } else {
                    commit(SET_REMOTE_DATA, {
                        namespace: "wdmCategories",
                        remoteData: {
                            status: "error",
                            errorMessage: e.message,
                        },
                    });
                }
            });
    },
};

export default actions;
