import Vue from "vue";

import { RESET, SET_ARTICLE_NUMBER, SET_REMOTE_DATA } from "./mutation-types";

const mutations = {
    [RESET](state) {
        state.articleNumber = "";
        state.abasArticle = {
            status: "empty",
            errorMessage: "",
            data: null,
        };
        state.wdmArticle = {
            status: "empty",
            errorMessage: "",
            data: null,
        };
        state.pdmProduct = {
            status: "empty",
            errorMessage: "",
            data: null,
        };
        state.pdmArticle = {
            status: "empty",
            errorMessage: "",
            data: null,
        };
        state.pdmTestPlan = {
            status: "empty",
            errorMessage: "",
            data: null,
        };
    },
    [SET_ARTICLE_NUMBER](state, { articleNumber }) {
        state.articleNumber = articleNumber;
    },
    [SET_REMOTE_DATA](state, { namespace, remoteData }) {
        let currentData = state[namespace];
        let newData = {};
        Object.assign(newData, currentData, remoteData);
        Vue.set(state, namespace, newData);
    },
};

export default mutations;
