<template>
    <div v-if="isVisible">
        <header-component
            v-bind:status="status"
            name="EINFACH packen"
        ></header-component>

        <div v-if="status === 'ERROR'">
            <div v-if="!isInWdm" class="alert alert-danger">
                Der Artikel ist nicht im WDM vorhanden.
            </div>
            <div v-if="!isStartDateInPast" class="alert alert-danger">
                Das Startdatum liegt in der Zukunft oder ist nicht gesetzt.
            </div>
            <div v-if="isEndDatePassed" class="alert alert-danger">
                Das Enddatum ist überschritten.
            </div>
        </div>
    </div>
</template>

<script>
import header from "./header.vue";
import { allFinished } from "../utils";

export default {
    components: {
        "header-component": header,
    },
    computed: {
        isVisible() {
            return allFinished(this.$store.state, ["wdmArticle", "pdmArticle"]);
        },

        article() {
            return this.$store.state.wdmArticle.data;
        },
        pdmArticle() {
            return this.$store.state.pdmArticle.data;
        },

        status() {
            if (this.isRegularSalesArticle) {
                return "IGNORE";
            }
            if (
                this.isInWdm &&
                this.isStartDateInPast &&
                !this.isEndDatePassed
            ) {
                return "OK";
            }
            return "ERROR";
        },

        isRegularSalesArticle() {
            if (this.pdmArticle != null) {
                return this.pdmArticle.type_of_article === "regular_sales";
            }
            return false;
        },

        isInWdm() {
            return this.article != null;
        },
        isStartDateInPast() {
            if (this.article == null) {
                return false;
            }
            return (
                this.article.start_date != null &&
                Date.parse(this.article.start_date) <= Date.now()
            );
        },
        isEndDatePassed() {
            if (this.article == null) {
                return false;
            }
            return (
                this.article.end_date != null &&
                Date.parse(this.article.end_date) < Date.now()
            );
        },
    },
};
</script>
