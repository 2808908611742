<template>
    <h3 v-bind:class="{ 'text-muted': status === 'IGNORE' }">
        {{ name }}
        <small>
            <a v-if="url" v-bind:href="url" target="_blank">
                <i class="fas fa-external-link-alt"></i>
            </a>
        </small>
        <i v-if="status === 'IGNORE'" class="fa fa-check text-muted"></i>
        <i v-else-if="status === 'OK'" class="fa fa-check text-success"></i>
        <i v-else-if="status === 'ERROR'" class="fa fa-times text-danger"></i>
    </h3>
</template>

<script>
export default {
    props: {
        status: String,
        url: String,
        name: String,
    },
    name: "header-component",
};
</script>

<style scoped></style>
