<template>
    <remoteData
        v-bind:data="data.data"
        v-bind:status="data.status"
        v-bind:errorMessage="data.errorMessage"
        v-bind:url="url"
        name="PDM"
    >
        <div v-if="product !== null">
            <div class="row">
                <div class="col-sm-10">
                    <dl class="dl-horizontal">
                        <dt>Name</dt>
                        <dd>{{ product.name }}</dd>
                        <dt>Intro</dt>
                        <dd>{{ product.intro }}</dd>
                        <dt>Beschreibung</dt>
                        <dd>{{ product.description }}</dd>
                    </dl>
                </div>
                <div class="col-sm-2">
                    <img
                        class="img-fluid"
                        v-if="product.image"
                        v-bind:src="product.image"
                        v-bind:alt="product.name"
                    />
                    <span v-else="product.image" class="thumbnail"
                        >Kein Bild</span
                    >
                </div>
            </div>
        </div>
    </remoteData>
</template>

<script>
import RemoteData from "./remote_data.vue";

export default {
    props: {
        data: Object,
    },
    computed: {
        product() {
            return this.data.data;
        },
        url() {
            if (!this.product) {
                return "https://proclima-pdm.moll-group.eu/admin/products/product/";
            }
            return (
                "https://proclima-pdm.moll-group.eu/admin/products/product/" +
                this.product.id +
                "/"
            );
        },
    },
    components: {
        remoteData: RemoteData,
    },
};
</script>
