function allFinished(state, remotes) {
    for (const remote of remotes) {
        let status = state[remote].status;
        if (status === "empty" || status === "loading") {
            return false;
        }
    }
    return true;
}

export { allFinished };
